import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { API_ADDRESS } from '../constants'

import { RefreshTokenRequest, RefreshTokenResponse } from '@models/Account'
import { clearTokens, decodeToken, setTokens } from './token'

const token = localStorage.getItem('token') || Cookies.get('token')

const REFRESH_TOKEN_PATH = '/api/token/refresh/'

const axiosInstance = axios.create({
  baseURL: API_ADDRESS,
  headers: {
    Authorization: token ? `Token ${token}` : undefined,
  },
  withCredentials: true,
})

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken') || Cookies.get('refreshToken')

  if (!refreshToken) {
    throw new Error('No refresh token')
  }
  try {
    const refreshResponse = await axios.post<RefreshTokenResponse>(
      REFRESH_TOKEN_PATH,
      {
        refresh: refreshToken,
      } as RefreshTokenRequest,
      {
        baseURL: API_ADDRESS,
      },
    )

    setTokens(refreshResponse.data.access, refreshResponse.data.refresh)
  } catch (err) {
    if (err) {
      clearTokens()

      axiosInstance.defaults.headers.Authorization = ''
    }
  }
}

export const checkTokenIsExpired = () => {
  const token = localStorage.getItem('token') || Cookies.get('token')
  if (!token) {
    return false
  }
  const decoded = decodeToken(token)

  if (!decoded.exp) {
    return false
  }

  if (decoded.exp < Date.now() / 1000) {
    return true
  }
  return false
}

if (token) {
  axiosInstance.defaults.headers.Authorization = `Token ${token}`
}

axiosInstance.interceptors.request.use(async (req) => {
  const isExpired = checkTokenIsExpired()

  if (isExpired) {
    await refreshToken()
  }

  const authToken = localStorage.getItem('token') || Cookies.get('token')
  const bearerToken = authToken ? `Token ${authToken}` : undefined
  if (bearerToken && axiosInstance.defaults.headers.Authorization !== bearerToken) {
    axiosInstance.defaults.headers.Authorization = bearerToken
    req.headers.Authorization = bearerToken
  }

  return req
})

axiosInstance.interceptors.response.use(undefined, (err) => {
  const url = (err?.response as AxiosError)?.config?.url
  if (
    err?.response?.status === 401 &&
    !['quickbooks', 'xero', 'softledger'].find((integration) => url?.includes(integration))
  ) {
    clearTokens()

    axiosInstance.defaults.headers.Authorization = ''
  }

  throw err
})
export default axiosInstance
