import { Buffer } from 'buffer'
import Cookies from 'js-cookie'

export const decodeToken = (token = ''): { exp: number; user_id: string } => {
  try {
    const dataPart = token.split('.')[1]
    return JSON.parse(Buffer.from(dataPart, 'base64').toString())
  } catch (err) {
    return {
      exp: 0,
      user_id: '',
    }
  }
}

export const setTokens = (token: string, refreshToken: string) => {
  localStorage.setItem('token', token)
  localStorage.setItem('refreshToken', refreshToken)
  Cookies.set('token', token, { expires: 7 })
  Cookies.set('refreshToken', refreshToken, { expires: 7 })
}

export const clearTokens = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  Cookies.remove('token')
  Cookies.remove('refreshToken')
}
